import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Tab, TabProps } from "semantic-ui-react";

// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
// Icon
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import SwipeableViews from "react-swipeable-views";
import { useHistory } from "react-router";

// UX
import CardAppointmentList from "./CardAppointmentList";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// appointment
import PulltoRefresh from "../appointment/PulltoRefresh";

// Interface
import { State, Event } from "../MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type MainAppointmentProps = {
  onEvent: (e: Event) => any;
} & Pick<
  State,
  | "myProfileDetail"
  | "requestedAppointmentList"
  | "hospitalList"
  | "loadingStatus"
  | "selectedHospital"
  | "myAppointmentList"
  | "cancelAppointmentList"
  | "allPaidInvoiceList"
  | "refundRequestsList"
  | "language"
>;

// Styles
const useStyles = makeStyles(() => ({
  screen: {
    "& > div:nth-child(2)": {
      height: "calc(100vh - 6rem)",
    },
    "& .react-swipeable-view-container > div > div": {
      height: "calc(100vh - 6rem)",
      padding: "0 15px",
      overflow: "scroll",
      position: "relative",
    },
    "& .button-submit-bottom": {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      paddingBottom: "35px",
    },
  },
  pull_to_refresh: {
    "& .ptr__pull-down": {
      top: "1rem",
    },
    "& .ptr__children > div:last-child": {
      marginBottom: "7rem",
    },
  },
}));

// Const
const TABS = {
  appointment: 0,
  requested: 1,
  cancel: 2,
} as any;

const panes = [
  { menuItem: "นัดหมายของฉัน" },
  { menuItem: "คำขอนัดหมาย" },
  { menuItem: "ยกเลิกนัดหมาย" },
];

const BUTTON_ACTIONS = {
  requested: "_REQUESTED",
  appointment: "_APPOINTMENT",
  cancel: "_CANCEL",
};

const params = new URLSearchParams(window.location.search);
const app = params.get("app");

const MOB_APP = `app=${app}`;

const MAIN_APPOINTMENT = "MainAppointment";

const ACTION_REQUESTED = `${MAIN_APPOINTMENT}${BUTTON_ACTIONS.requested}`;
const ACTION_APPOINTMENT = `${MAIN_APPOINTMENT}${BUTTON_ACTIONS.appointment}`;
const ACTION_CANCEL = `${MAIN_APPOINTMENT}${BUTTON_ACTIONS.cancel}`;

const MainAppointment = (props: MainAppointmentProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [viewIndex, setViewIndex] = useState<number | string>(0);

  useEffect(() => {
    handleGetListPaidInvoice();

    props.onEvent({
      message: "GetListMyAppointment",
      params: {
        // patient: props?.selectedHospital?.patient_id,
        card: ACTION_APPOINTMENT,
        hospital: props.selectedHospital?.code,
      },
    });
    
  }, []);

  // Callback
  const handleGetListRequestedApp = useCallback(() => {
    props.onEvent({
      message: "GetListRequestedAppointment",
      params: {
        patient: props?.selectedHospital?.patient_id,
        card: ACTION_REQUESTED,
      },
    });
  }, [props.myProfileDetail]);

  const handleGetListMyApp = useCallback(() => {
    props.onEvent({
      message: "GetListMyAppointment",
      params: {
        // patient: props?.selectedHospital?.patient_id,
        card: ACTION_APPOINTMENT,
        hospital: props.selectedHospital?.code,
      },
    });
  }, [props.myProfileDetail, props.selectedHospital]);

  const handleGetListCancelApp = useCallback(() => {
    props.onEvent({
      message: "GetListCancelAppointment",
      params: {
        // patient: props?.selectedHospital?.patient_id,
        card: ACTION_CANCEL,
        hospital: props.selectedHospital?.code,
      },
    });
  }, [props.myProfileDetail, props.selectedHospital]);

  const handleGetListPaidInvoice = useCallback(() => {
    props.onEvent({
      message: "GetListAllPaidInvoice",
      params: {
        patientId: props?.selectedHospital?.patient_id,
        card: ACTION_CANCEL,
        hospitalCode: props.selectedHospital?.code,
      },
    });

    props.onEvent({
      message: "GetRefundRequests",
      params: {
        patient: props?.selectedHospital?.patient_id,
        card: ACTION_CANCEL,
        hospital: props.selectedHospital?.code,
      },
    });

  }, [props.myProfileDetail, props.selectedHospital]);

  const formattedItem = useCallback((item) => {
    return {
      ...item,
      id: item.id,
      type: "doctor" as const,
    };
  }, []);

  // Effect
  useEffect(() => {
    if (
      props.myProfileDetail?.patient &&
      !props.requestedAppointmentList?.length
    ) {
      handleGetListRequestedApp();
    }
  }, [props.myProfileDetail]);

  useEffect(() => {
    const refresh =
      !props.myAppointmentList?.length || !props.cancelAppointmentList?.length;

    if (props.myProfileDetail?.patient && refresh) {
      handleGetListMyApp();
      handleGetListCancelApp();
    }
  }, [props.myProfileDetail, props.selectedHospital]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const tab = params.get("tab") || "";

    if (typeof TABS[tab] !== "undefined") {
      setViewIndex(TABS[tab]);

      history.replace(`/?${MOB_APP}`);
    }
  }, [history.location]);

  // Memo
  const requestedAppointmentItems = useMemo(() => {
    return (props.requestedAppointmentList || []).map((item) => {
      return formattedItem({
        ...item,
        status: "REQUESTED",
      });
    });
  }, [props.requestedAppointmentList, props.hospitalList]);

  const myAppointmentItems = useMemo(() => {
    return (props.myAppointmentList || []).map((item) => {
      return formattedItem(item);
    });
  }, [props.myAppointmentList, props.hospitalList]);

  const cancelAppointmentItems = useMemo(() => {
    return (props.cancelAppointmentList || []).map((item) => {
      return formattedItem({
        ...item,
        status: "CANCELED",
      });
    });
  }, [props.cancelAppointmentList, props.hospitalList]);

  const waiting = useMemo(() => {
    return !props.myProfileDetail?.patient;
  }, [props.myProfileDetail, props.selectedHospital]);

  const loading = useMemo(() => {
    return {
      appointment:
        props.loadingStatus?.[ACTION_APPOINTMENT] ||
        waiting ||
        !props.myAppointmentList,
      requested:
        props.loadingStatus?.[ACTION_REQUESTED] ||
        waiting ||
        !props.requestedAppointmentList,
      cancel:
        props.loadingStatus?.[ACTION_CANCEL] ||
        waiting ||
        !props.cancelAppointmentList,
    };
  }, [
    props.loadingStatus,
    waiting,
    props.requestedAppointmentList,
    props.myAppointmentList,
    props.cancelAppointmentList,
  ]);

  // Handler
  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleCreateAppointment = () => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        pathname: URLS.SELECT_CENTERGROUP,
        history,
      },
    });
  };

  return (
    <div>
      <HeaderBar
        onEvent={() => {}}
        // data
        // history={this.props.history}
        setTitle="นัดหมายแพทย์"
        whiteTheme={true}
        // config
        hiddenRight={true}
        hiddenLeft={true}
      />

      <div className={classes.screen}>
        <Tab
          className="appointment-tab-custom --red --small --three"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={viewIndex}
          onTabChange={handleTabChange}
        />

        <SwipeableViews
          index={+viewIndex}
          onChangeIndex={handleChangeIndex}
          animateHeight={true}
        >
          {/* นัดหมายของฉัน */}
          <div>
            <PulltoRefresh
              className={classes.pull_to_refresh}
              isPullable={!loading.appointment}
              onRefresh={() => {
                handleGetListPaidInvoice();
                handleGetListMyApp();
              }}
            >
              <CardAppointmentList
                onEvent={props.onEvent}
                language={props.language}
                items={myAppointmentItems}
                loading={loading.appointment}
                allPaidInvoiceList={props.allPaidInvoiceList}
              />
            </PulltoRefresh>
          </div>
          {/* คำขอนัดหมาย */}
          <div>
            <PulltoRefresh
              className={classes.pull_to_refresh}
              isPullable={!loading.appointment}
              onRefresh={handleGetListRequestedApp}
            >
              <CardAppointmentList
                onEvent={props.onEvent}
                language={props.language}
                items={requestedAppointmentItems}
                loading={loading.requested}
                noResultsMessage={{ title: "ไม่มีคำขอนัดหมาย" }}
              />
            </PulltoRefresh>
          </div>
          {/* ยกเลิกนัดหมาย */}
          <div>
            <PulltoRefresh
              className={classes.pull_to_refresh}
              isPullable={!loading.cancel}
              onRefresh={() => {
                handleGetListPaidInvoice();
                handleGetListCancelApp();
              }}
            >
              <CardAppointmentList
                onEvent={props.onEvent}
                language={props.language}
                items={cancelAppointmentItems}
                loading={loading.cancel}
                refundRequestsList={props.refundRequestsList}
                allPaidInvoiceList={props.allPaidInvoiceList}
                noResultsMessage={{ title: "ไม่มียกเลิกนัดหมาย" }}
              />
            </PulltoRefresh>
          </div>
        </SwipeableViews>

        <div className="button-submit-bottom">
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleCreateAppointment}
          >
            <AddRoundedIcon />
            <label style={{ marginLeft: "0.5rem" }}>สร้างนัดหมาย</label>
          </MuiButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MainAppointment);

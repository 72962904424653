import WasmController from "react-lib/frameworks/WasmController";

import { History } from "history";
import Cookies from "js-cookie";
import axios from "axios";

// APIs
// PRX
import ListConsentsView from "issara-sdk/apis/ListConsentsView_apps_PRX";
import ProxyRequestedAppointmentM from "issara-sdk/apis/ProxyRequestedAppointmentM";
import V3AppointmentView from "issara-sdk/apis/V3AppointmentView_apps_PRX";
import V3CancelAppointment from "issara-sdk/apis/V3CancelAppointment_apps_PRX";
import DoctorReviews from "issara-sdk/apis/DoctorReviews_apps_PRX";
import V3EditAppointmentView from "issara-sdk/apis/V3EditAppointmentView_apps_PRX";
import SmartAppointmentPendingPayment from "issara-sdk/apis/SmartAppointmentPendingPayment_apps_PRX";
import SmartAppointmentRefundRequests from "issara-sdk/apis/SmartAppointmentRefundRequests_apps_PRX";
import UserFamily from "issara-sdk/apis/V3UserFamily_apps_PRX";
import SmartUserState from "issara-sdk/apis/SmartUserState_apps_PRX";
import DeactivateAppointment from "issara-sdk/apis/DeactivateAppointmentView_apps_PRX";
// profile
import ProxyMyProfile from "issara-sdk/apis/ProxyMyProfile_profileM";

// Interface
import * as DoctorAppointmentI from "./smartappointment/DoctorAppointmentInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as SelectDateI from "./appointment/SelectDateInterface";
import * as MobAppointmentI from "./MobAppointmentInterface";
import * as PaymentI from "./MobPaymentInterface";

// Types
import { DoctorSerializer } from "./smartappointment/Types";

// config
import CONFIG from "config/config";

// Utils

// Config

// Types
export type State = Partial<{
  loggedin: boolean;
  platform: string;
  theme: string;
  projectName: string;
  device_id: string | number;
  subscription: any;
  apiToken: string;
  userId: string | number;
  language: "en-US" | "th-TH";

  // Common
  myProfileDetail: any;

  // message
  loadingStatus: Record<string, any>;
  errorMessage: Record<string, any>;
  successMessage: Record<string, any>;

  // Search
  textSearch: string;
  loadingSearch: boolean;

  // Appointment
  topServiceList: any[];
  requestedAppointmentList: Record<string, any>[];
  myAppointmentList: Record<string, any>[];
  cancelAppointmentList: Record<string, any>[];
  refundRequestsList: Record<string, any>[];

  doctorProfile: DoctorSerializer;
}> &
  SelectHospitalI.State &
  DoctorAppointmentI.State &
  SelectDateI.State &
  PaymentI.State &
  MobAppointmentI.State;

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  loadingStatus: {},
  errorMessage: {},
  successMessage: {},
  ...SelectHospitalI.StateInitial,
  ...DoctorAppointmentI.StateInitial,
  ...SelectDateI.StateInitial,
  ...PaymentI.StateInitial,
};

export type Event =
  // Native
  | { message: "GetLoginInfo"; params: {} }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  // Appointment
  | { message: "GetMyProfileDetail"; params: {} }
  | { message: "GetDoctorProfile"; params: { id: number ,card: string} }
  | {
      message: "HandleHistoryPushState";
      params: {
        history: History;
        pathname?: string;
        state?: Record<string, any>;
        replaceState?: Record<string, any>; // แทนที่ state เดิม
        storedState?: Record<string, any> | null;
        reload?: boolean;
        search?: string;
        replace?: boolean;
        cutOff?: string;
      };
    }
  | {
      message: "HandlePrepareMakeAppointment";
      params: { history: any; card: string; type: string; skipPopup?: boolean };
    }
  | {
      message: "GetListRequestedAppointment";
      params: { patient: number; card: string };
    }
  | {
      message: "CancelAppointment";
      params: {
        card: string;
        hospital_code: string;
        appointment_id: string;
        doctor_code: string;
        reason: string;
        date: string; //YYYY-MM-DD
        time: string;
        location_code: string;
        patient: number;
        type: "CRM" | "REALTIME";
        // refund
        invoice_ref?: string;
        total?: string;
      };
    }
  | {
      message: "GetListMyAppointment";
      params: {
        patient?: number;
        card: string;
        hospital: string;
      };
    }
  | {
      message: "GetListCancelAppointment";
      params: {
        patient?: number;
        card: string;
        hospital: string;
      };
    }
  | {
      message: "HandleEditAppointment";
      params: { history?: any; card: string; slot: any };
    }
  | {
      message: "GetRefundRequests";
      params: { hospital?: any; patient: any; card: string };
    }
  | SelectHospitalI.Event
  | DoctorAppointmentI.Event
  | SelectDateI.Event
  | PaymentI.Event
  | MobAppointmentI.Event;

export type Data = {};

export const DataInitial = {};

const params = new URLSearchParams(window.location.search);
const app = params.get("app");

const MOB_APP = `app=${app}`;

const BUTTON_ACTIONS = {
  confirm: "_CONFIRM",
  request: "_REQUEST",
};

const CONFIRM_APPOINTMENT = "ConfirmAppointment";
export const ACTION_CONFIRM = `${CONFIRM_APPOINTMENT}${BUTTON_ACTIONS.confirm}`;
export const ACTION_REQUEST = `${CONFIRM_APPOINTMENT}${BUTTON_ACTIONS.request}`;

type Handler<P = any, R = any> = (
  controller: WasmController<State, Event, Data>,
  params: P
) => R;

type Params<A extends Event["message"]> = Extract<
  Event,
  { message: A }
>["params"];

export const DidMount: Handler = async (controller, params) => {
  console.log("MobSmartAppointment DidMount: ");

  SelectHospitalI.SelectedInitialHospital(controller, params);
};

export const DidUpdate: Handler = async (controller, params) => {
  //
};

export const GetMyProfileDetail: Handler = async (controller, params) => {
  const [res] = await ProxyMyProfile.get({
    apiToken: controller.cookies.get("apiToken"),
  });

  let userId = controller.cookies.get("userId");

  controller.analytics.setUserId(
    userId !== undefined || userId !== "" ? userId : "0"
  );

  controller.setState({ myProfileDetail: res || {} });
};

export const HandleHistoryPushState: Handler<
  Params<"HandleHistoryPushState">
> = (controller, params) => {
  const locState = params.history.location.state || {};
  const history = params.history;
  let state = params.state || {};
  const currentPathname = history.location.pathname;
  const isReplace = typeof params.replace === "undefined" || params.replace;

  if (isReplace && !params.cutOff) {
    history.replace(`${currentPathname}${history.location.search}`, {
      ...locState,
      storedState:
        typeof params.storedState === "undefined" ? state : params.storedState,
    });
  }

  const { storedState, ...rest } = locState as any;

  if (params.pathname) {
    const routes = [...(rest.routes || []), currentPathname];
    const search = params.search || `?${MOB_APP}`;
    const cutoffRouter = params.cutOff
      ? cutoffRoutes(routes, params.cutOff || "")
      : routes;
    console.log("saika ~ routes:", routes)
    console.log("saika ~ cutoffRouter:", cutoffRouter)
    const concatState =
      typeof params.replaceState !== "undefined"
        ? params.replaceState
        : {
            ...rest,
            ...state,
            ...(params.storedState && { storedState: params.storedState }),
          };

    state = {
      ...concatState,
      routes:cutoffRouter,
    };

    history.push({ pathname: params.pathname, search, state });

    if (params.cutOff) {
      const BackStep = routes.length - cutoffRouter.length;
      globalThis.history.go(-(BackStep));
      setTimeout(
        () => history.replace({ pathname: params.pathname, search, state }),
        10
      );
    }

    if (params.reload) {
      globalThis.history.go();
    }
  }
};

const cutoffRoutes = (routes: string[], cutoff: string) => {
  const cutoffIndex = routes.indexOf(cutoff);
  if (cutoffIndex === -1) {
    return routes;
  }
  return routes.slice(0, cutoffIndex );
};

export const GetListRequestedAppointment: Handler<
  Params<"GetListRequestedAppointment">
> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [res] = await UserFamily.list({
    apiToken: controller.cookies.get("apiToken"),
  })

  let listPatient = [params.patient]
  if (!params.patient) {
    listPatient = []
  }

  if (res) {
    (res.items || []).forEach((item:any)=> {
      if (item.patient.patient) {
        listPatient.push(item.patient.patient)
      }
    })
  }

  const [result] = await ProxyRequestedAppointmentM.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      ...(listPatient.length > 0 && {patient: [...listPatient]}),
      exclude_outdated: true,
    },
  });

  state = controller.getState();

  controller.setState({
    requestedAppointmentList: result?.items || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const CancelAppointment: Handler<Params<"CancelAppointment">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  let cancelApi:any

  if (params.type === "CRM") {
    cancelApi = DeactivateAppointment.post({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data:{
        appointment: params.appointment_id,
        patient: params.patient,
        hospital: params.hospital_code,
      }
      })
  } else {
    cancelApi = V3CancelAppointment.post({
      apiToken: controller.cookies.get("apiToken"),
      data: {
        hospital: params.hospital_code,
        appointment_id: params.appointment_id,
        doctor_code: params.doctor_code,
        reason: params.reason,
        date: params.date,
        time: params.time,
        location_code: params.location_code,
        patient: params.patient,
      },
    })
  }
  let promiseArray = [cancelApi];
  if (params?.invoice_ref) {
    promiseArray.push(
      SmartAppointmentRefundRequests.post({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        data: {
          patient: params.patient,
          hospital: params.hospital_code,
          book_id: params.appointment_id,
          invoice_ref: params.invoice_ref,
          total: params.total,
          reason: params.reason,
        },
      })
    );
  }

  const [cancelRes, refundRes] = await Promise.all(promiseArray);

  if (cancelRes?.[1] || refundRes?.[1]) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
      errorMessage: {
        ...state.errorMessage,
        [params.card]:
          cancelRes?.[1]?.ResponseStatus?.Description || refundRes?.[1] || "error",
      },
    });
  } else {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
      successMessage: { ...state.loadingStatus, [params.card]: true },
    });
  }
};

export const GetListMyAppointment: Handler<
  Params<"GetListMyAppointment">
> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [result] = await GetAppointment(controller, params);

  state = controller.getState();

  let realtimeList = result?.items.map((item: any) => {
    return { ...item, ...(params.hospital === "1" && {realtime:  "REALTIME"})};
  });

  const filterCancel = (realtimeList || []).filter((items:any) => !items.canceled_at)

  controller.setState({
    myAppointmentList: filterCancel || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const GetListCancelAppointment: Handler<
  Params<"GetListCancelAppointment">
> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [result] = await GetAppointment(controller, {
    ...params,
    cancel: true,
  });

  state = controller.getState();

  controller.setState({
    cancelAppointmentList: result?.items || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const GetDoctorProfile: Handler<Params<"GetDoctorProfile">> = async (
  controller,
  params
) => {
  const state = controller.getState();
  controller.setState({
    loadingSkeleton: true,
  });

  const result = await axios.get(
    `${CONFIG.API_HOST}/apis/PRX/v3/doctor/${params.id}/`,
    {
      headers: { Authorization: `Token ${controller.cookies.get("apiToken")}` },
    }
  );

  const [resReview, errorReview] = await DoctorReviews.retrieve({
    apiToken: Cookies.get("apiToken"),
    doctor_id: result.data.id,
  });

  controller.setState({
    doctorProfile: { ...result.data, review: resReview || {} },
    loadingSkeleton: false,
  });
};

export const HandlePrepareMakeAppointment: Handler<
  Params<"HandlePrepareMakeAppointment">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const locState =
    typeof params.history.location.state === "string"
      ? JSON.parse(params.history.location.state as any).locationState
      : params.history.location.state || {};

  const selectedSlot = locState.slot?.times?.[0] || {};

  const { slot_id, location, raw_date, raw_start_time, doctor } = selectedSlot;

  let note_text =
    (locState?.reason?.note || "") +
    (locState?.reason?.noteSymptoms || "") +
    (locState?.reason?.noteLongTime || "");

  // patient id ของผู้ทำนัดหมาย,นัดให้คนอื่น| นัดให้ family| นัดให้ตัวเอง
  const patientId = locState.member
    ? locState.member?.patient
    : state.myProfileDetail?.patient;

  const hospitalCode = locState.hospital?.code ?? "1";

  const isTelemed = locState.appointmentLocation === "online";

  // -const splitSubspecialty: any = (props.state?.subspecialty || "")
  //   ?.toString()
  //   ?.split(",");

  const data: any = {
    patient: patientId,
    doctor_code: doctor?.doctor_code,
    note: note_text,
    hospital: hospitalCode,
    slot_id: slot_id,
    date: raw_date,
    time: raw_start_time,
    location_code: location,
    // service: props.state.service,
    // med_program: props.state?.med_program,
    specialty: null,
    // subspecialty: splitSubspecialty?.[0] ? +splitSubspecialty?.[0] : null,
    med_service:
      isTelemed && locState?.requestType === "REALTIME"
        ? CONFIG.MED_SERVICE_TELEMED_ID
        : locState?.reason?.id,
  };

  // Note:
  // online flag ที่ชื่อเพี้ยนตัวนี้ = true = realtime
  // online flag ที่ชื่อเพี้ยนตัวนี้ = false = CRM

  const times = locState.slot?.times || [];
  const startTime = times[0]?.start_time || "";
  const endTime = times.slice(-1)[0]?.end_time || "";

  const dataCRM: any = {
    doctor_code: doctor?.doctor_code,
    date: raw_date,
    start_time: startTime,
    end_time: endTime,
    patient: patientId,
    reason: note_text,
    location_code: location,
    hospital: hospitalCode,
  };

  if (locState.requestType === "CRM") {
    dataCRM.is_telemed = false;
  }

  if (isTelemed) {
    dataCRM.is_telemed = true;
  }

  if (locState.doctor?.code) {
    dataCRM.doctor_code = locState.doctor.code;
  }

  const paramsCheckCoupon = {
    hospital: hospitalCode,
    // coupon: props.state?.coupon_number,
  };

  const [hospitalList] = await ListConsentsView.get({
    apiToken: Cookies.get("apiToken"),
  });

  const consent =
    (hospitalList || []).find(
      (item: any) => String(data.hospital) === item.organization_code
    ) || null;

  // -let full_name = `${props.myProfileDetail?.first_name} ${props.myProfileDetail?.last_name}`;

  // if (locState.family) {
  //   full_name = `${locState.family?.first_name} ${locState.family?.last_name}`;
  // }
  // -if (props.state?.book_for_some_else) {
  //   full_name = `${patientForSomeElse?.first_name} ${patientForSomeElse?.last_name}`;
  // }

  const appointmentData = {
    data,
    history: params.history,
    dataCRM,
    paramsCheckCoupon,
    is_telemed: isTelemed,
    request_type: locState.requestType,
    // is_moderna: isModernaVaccine,
    // book_for_some_else: props.state?.book_for_some_else,
    locationState: locState,
    appointment_type: locState.appointmentType,
    // reasonError:
    //   props.language === "th-TH"
    //     ? { reason: ["ไม่สามารถระบุค่าว่าง '' ที่ช่องนี้"] }
    //     : { reason: ["This field may not be blank."] },
    hospital: locState.hospital?.code,
    doctor_id: locState.doctor?.id,
    published_diag_rule: consent?.published_diag_rule,
    diag_rule: consent?.diag_rule,
    // skipConsent: true,
    actionType: params.type,
  };

  const [res, error] = await controller.handleEvent({
    message: "MakeAppointment",
    params: appointmentData,
  });

  if (res === "pending") {
    return;
  }

  if (params.type !== "PAYMENT") {
    await controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });
  }

  if (error) {
    if (error?.kyc) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [`${params.card}_KYC`]: error?.kyc,
        },
      });
    } else if (
      error?.ResponseStatus?.Description?.includes("full") ||
      error?.ResponseStatus?.Description?.includes("นัดเต็มแล้ว")
    ) {
      controller.setState({
        errorMessage: {
          ...state.successMessage,
          [`${params.card}_FULL`]: true,
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.successMessage, [params.card]: true },
      });
    }
  } else if (params.type === "PAYMENT") {
    const doctorFee = locState.division?.price || "0";

    const backURL = encodeURIComponent(
      `${
        window.location.origin
      }/payment-complete/${"appointment"}/?app=MobPayment&deliveryflag=false&price=${doctorFee}`
    );

    const [result, error] = await SmartAppointmentPendingPayment.get({
      apiToken: controller.cookies.get("apiToken"),
      params: {
        back_url: backURL,
        ...(locState.member && { patient: locState.member?.patient }),
        hospital: hospitalCode
      },
    });

    console.log("result smartappointment:", result);

    const appointmentId = res.Result?.Appointment?.ID || "";
    await controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });

    if (result) {
      const filter = (result || []).filter((item: any) => {
        return item?.book_id === appointmentId;
      });

      if (filter?.[0]) {
        globalThis.location.href = filter[0]?.override_payment_link;
      } else {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.card]: true },
        });
      }
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: true },
      });
      console.log("error smartappointment: ", error);
    }
  } else {
    if (!res.is_telemed && !res.ResponseStatus) {
        // ถ้าสร้าง appointment แล้วไม่ได้ realtime
        // จาก CONFIRM ต้องเปลี่ยนเป็น REQUEST
        await controller.setState({
          successMessage: { ...state.successMessage, [ACTION_REQUEST]: true },
        });

        // if (params.skipPopup) {
        //   controller.handleEvent({
        //     message: "HandleHistoryPushState",
        //     params: {
        //       history: params.history,
        //       state: { complete_appointment: true },
        //       storedState: {},
        //     },
        //   });
        // }
    } else {
        await controller.setState({
          successMessage: { ...state.successMessage, [params.card]: true },
        });

        // if (params.skipPopup) {
        //   controller.handleEvent({
        //     message: "HandleHistoryPushState",
        //     params: {
        //       history: params.history,
        //       state: { complete_appointment: true },
        //       storedState: {},
        //     },
        //   });
        // }
      }
    }

  // -history.push({
  //   pathname: `/complete/?app=${"MobAppointment"}`,
  //   state: {
  //     ...props.state,
  //     time: `${selectedSlot.start_time}-${selectedSlot.end_time}`,
  //     id: res?.Result?.Appointment?.ID ?? "",
  //     date: raw_date,
  //     full_name: `${full_name}`,
  //   },
  // });
};

export const HandleEditAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });
  const history = params.history;
  const locState = history.location.state || {};
  const hospital = locState.hospital.code;
  const appointment = locState.editAppointment.id;

  const [resAppointment, errAppointment] = await V3EditAppointmentView.post({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: {
      hospital: hospital,
      appointment_id: appointment,
      ...params.slot,
    },
  });

  if (errAppointment) {
    await controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: true },
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });
    return;
  } else if (resAppointment) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.card]: false },
    });
    return history.goBack();
  }
};

export const GetRefundRequests: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [res, error] = await SmartAppointmentRefundRequests.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: params.patient,
      hospital: params.hospital_code,
    },
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
    refundRequestsList: res,
  });
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetAppointment: Handler = async (controller, params) => {
  return await V3AppointmentView.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      patient: params.patient,
      hospital: params.hospital,
      cancel: params.cancel,
    },
  });
};

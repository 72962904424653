import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { Form, Input } from "semantic-ui-react";
import { useIntl } from "react-intl";

const ModAuthen = React.forwardRef((props: any, ref) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null)



  useEffect(() => {
    if (props.error) {
      setError(props.error);
    }
  }, [props.error]);


  useEffect(() => {
    if (props.open) {
      setUsername("");
      setPassword("");
      setError(null);
    }
  }, [props.open]);


  React.useImperativeHandle(ref, () => ({
    clear: () => {
      setUsername("");
      setPassword("");
      setError(null);
    }
  }));

  return (
    <ModConfirm
      titleColor={props.titleColor}
      backgroundColor={props.backgroundColor}
      openModal={props.open}
      titleName={props.titleName}
      onDeny={props.onDeny}
      onCloseWithDimmerClick={props.onCloseWithDimmerClick}
      onApprove={() => props.onApprove({ username, password })}
      loading={props.loading}
      approveButtonText={props.approveButtonText}
      approveButtonLoading={props.approveButtonLoading}
      denyButtonText={props.denyButtonText}
      modalStyle={props.style}
      basic={props.basic}
      customGridGroup={props.customGridGroup}
      customGridButton={props.customGridButton}
      denyButtonColor={props.denyButtonColor}
      approveButtonColor={props.approveButtonColor}
      content={
        <Form
          error={error ? true : false}
          onSubmit={props.approveButtonText}
          style={{ margin: "-0.25rem 0 -0.25rem" }}
        >
          <ErrorMessage error={error} />
          <Form.Field
            label="Username"
            error={error ? true : false}
            style={{ marginBottom: "0.5rem" }}
          />
          <Form.Field error={error ? true : false}>
            <Input
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Field>
          <Form.Field
            label="Password"
            error={error ? true : false}
            style={{ marginBottom: "0.5rem" }}
          />
          <Form.Field error={error ? true : false}>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Field>
          {props.children}
        </Form>
      }
    />
  );
});

ModAuthen.defaultProps = {
  open: false,
  titleName: "กรุณายืนยัน Username และ Password อีกครั้ง",
  onDeny: () => {},
  error: null,
  onApprove: () => {},
  loading: false,
  style: {},
  basic: true,
};

ModAuthen.propTypes = {
  open: PropTypes.bool,
  titleName: PropTypes.string,
  onDeny: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onApprove: PropTypes.func,
  headerColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object,
  approveButtonLoading: PropTypes.bool,
  basic: PropTypes.bool,
};

export default React.memo(ModAuthen);

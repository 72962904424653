import WasmController from "../../react-lib/frameworks/WasmController";

import Cookies from "js-cookie";

// APIS
import V3PatientHospital from "issara-sdk/apis/V3PatientHospital_apps_PRX";
import V3PatientGetHN from "issara-sdk/apis/V3PatientGetHN_apps_PRX"

export type State = Partial<{
  selectedHospital: HospitalType | null;
  hospitalList: HospitalType[];
  loadingHospital: boolean;
}>;

export type HospitalType = { code: string; name: string; hn?: string; patient_id?: number; phone?: string; email?: string; organization?:Organization;};
export type Organization = { 
   id?: number;
   code?: string ;
   name?: string ;
   name_en?: string ;
   hospital_type?: string;
   main_division?: number;
   email?: string;
   telephone_1?: string;
   telephone_2?: string;
  };

export const StateInitial: State = {
  selectedHospital: null,
  hospitalList: [],
};

export type Event =
  | { message: "SelectedInitialHospital"; params: {} }
  | { message: "HandleSelectedHospital"; params: { data: HospitalType } }
  | { message: "GetListHospital"; params: {} };

export type Data = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

type Params<M> = Extract<Event, { message: M }>["params"];

// เมื่อ dev บน browser
export const USE_LOCAL_STORAGE = !globalThis.mobile?.handleEvent;

export const SelectedInitialHospital: Handler = async (controller, params) => {
  console.log('!!! !!! SelectedInitialHospital: ');
  // มี State ใช้ State
  // มี native ใช้ native
  // ไม่มีอะไรเลย ยิง API เพือนำมาใช้
  if (params.reset) {
    console.log("SelectedInitialHospital GetListHospital params.reset !!! ")
    const [res, error] = await GetHospital(controller, params);
    console.log("SelectedInitialHospital GetListHospital params.reset !!! ")
    const hospitalList: HospitalType[] = res?.items || [];
    // TODO : ต้องไม่เลือกให้ user ต้องให้ User เลือกเอง 
    let selectHospital = hospitalList?.length === 1 ? hospitalList[0] : null
    await HandleSelectedHospital(controller, { data: selectHospital });
    return [selectHospital, error];
  }

  /// ถ้า เป็น WebMode อย่างเดียว ใช้ ของ ที่เก็บอยู่ใน Web
  const localStorageHospitalList = localStorage.getItem("storedHospitalList");
  const localStoragePreferredHospital = localStorage.getItem("storedPreferredHospital");

  if (USE_LOCAL_STORAGE) {
    console.log(" -USE_LOCAL_STORAGE- ")
    if (!!localStorageHospitalList) {
      let hospital = JSON.parse(localStorageHospitalList || "[]");
      await controller.setState({
        hospitalList: hospital || [],
      });
    }

    if (!!localStoragePreferredHospital) {
      console.log(" -USE_LOCAL_STORAGE- --  ")
      let selectHospital = JSON.parse(
        localStoragePreferredHospital && localStoragePreferredHospital !== "undefined" ?
          localStoragePreferredHospital : "{}");
      await controller.setState({
        selectedHospital: selectHospital,
      });
      return [selectHospital]
    }
  }

  // if (isStorageHospital && USE_LOCAL_STORAGE) {
  //   let setSelectHospital = localStorage.getItem("storedPreferredHospital");
  //   let hospitalList = localStorage.getItem("storedHospitalList");
  //   let hospital = JSON.parse(hospitalList || "[]");
  //   let selectHospital = JSON.parse(
  //     setSelectHospital && setSelectHospital !== "undefined" ?
  //       setSelectHospital : "{}");

  //   controller.setState({
  //     selectedHospital: selectHospital,
  //     hospitalList: hospital || [],
  //   });
  // return [selectHospital]

  // ตรวจ ดู ใน Native iOS หรือ Android

  // ------ iOS ------
  if (globalThis.mobile?.saveiOSSelectedHospital) {
    // เป็น iOS ไม่ต้องเรียก จริง แค่ต้องการรู้ว่า เป็น platform ไหน 
    let hospitalList = await globalThis.mobile?.storedHospitalList;
    let code = await globalThis.mobile.storedHospitalCode;
    let name = await globalThis.mobile.storedHospitalName;

    console.log('SelectedInitialHospital hospitalList: ', hospitalList);
    console.log('SelectedInitialHospital code: ', code);
    console.log('SelectedInitialHospital name: ', name);

    let hospital = hospitalList && JSON.parse(hospitalList);
    const selectedHos = (hospital || []).find((items:any) => items.code === code) || {}
    controller.setState({
      ...(hospital && { hospitalList: hospital || [] }),
      ...(selectedHos && { selectedHospital: { ...selectedHos, code: code, name: name } })
    });

    if (!code || !name) {
      return [null]
    }

    if (code && name) {
      return [{ code: code, name: name }];
    }

  } else if (
    globalThis.mobile.storedPreferredHospital &&
    globalThis.mobile.storedHospitalList
  ) {
    // Android
    let setSelectHospital = await globalThis.mobile.storedPreferredHospital();
    let hospitalList = await globalThis.mobile.storedHospitalList();

    let selectHospital = !!setSelectHospital && JSON.parse(setSelectHospital);
    let hospital = !!hospitalList && JSON.parse(hospitalList);

    console.log("SelectedInitialHospital ~ Android selectHospital: ", selectHospital)
    console.log("SelectedInitialHospital ~ Android hospital:", hospital)

    controller.setState({
      ...(hospital && { hospitalList: hospital || [] }),
      ...(selectHospital && { selectedHospital: selectHospital })
    });

    if (!setSelectHospital) {
      return [null]
    }

    if (setSelectHospital) {
      return [selectHospital];
    }
  }

  // ไม่มี ใน native, ไม่มีใน localstage

  console.log("SelectedInitialHospital ไม่มี ใน native, ไม่มีใน localstage ")
  const [res, error] = await GetHospital(controller, params);
  const hospitalList: HospitalType[] = res?.items || [];
  // TODO: ต้องให้ผู้ใช้เลือก ก่อน ไม่เลือกให้อัตโนมัติ
  // const selectHospital = hospitalList[0];
  console.log("!!! !!! SelectedInitialHospital Web Get API GetHospital res: ", res)
  console.log("!!! !!! SelectedInitialHospital Web Get API GetHospital error: ", error)
  if (USE_LOCAL_STORAGE) {
    // localStorage.setItem(
    //   "storedPreferredHospital",
    //   JSON.stringify(selectHospital)
    // );
    localStorage.setItem("storedHospitalList", JSON.stringify(hospitalList));
    if (hospitalList?.length > 0) {
      localStorage.setItem("storedPreferredHospital", JSON.stringify(hospitalList?.[0]));
    }
  } else {

    if (!!localStorageHospitalList) {
      localStorage.removeItem("storedHospitalList");
    }

    if (!!localStoragePreferredHospital) {
      // TODO: เลือกตัวก่อนหน้า คืนให้ แค่ refresh มาเฉยๆ 
      localStorage.removeItem("storedPreferredHospital");
    }
  }

  //  บันทึก ใส่ native
  if (globalThis.mobile.saveiOSHospitalList) {
    // iOS
   let hospitalListStr = hospitalList.map((item:HospitalType ) =>  {
     return {...item, patient_id: `${item.patient_id}`}
   })
    globalThis.mobile.saveiOSHospitalList(hospitalListStr);
  } else if (globalThis.mobile.saveHospitalList) {
    // Android
    globalThis.mobile.saveHospitalList(JSON.stringify(hospitalList));
  } else {
    // Web 
    controller.setState({ selectedHospital: hospitalList?.[0] });
  }

  controller.setState({
    hospitalList: hospitalList,
    // selectedHospital: selectHospital,
  });

  return [null, error];
};

export const HandleSelectedHospital: Handler = (
  controller,
  params: Params<"HandleSelectedHospital">
) => {
  const data = JSON.parse(JSON.stringify(params.data));

  if (window.mobile?.saveiOSSelectedHospital) {
    // iOS mobile
    let hospitalStr = {...params.data, patient_id: `${params.data.patient_id}`}
    window.mobile.saveiOSSelectedHospital(hospitalStr);
  } else if (window.iosNative?.saveiOSSelectedHospital) {
    // iOS iosNative
    let hospitalStr = {...params.data, patient_id: `${params.data.patient_id}`}
    window.iosNative.saveiOSSelectedHospital(hospitalStr);
  } else if (window.mobile?.storeAndroidSelectedHospital) {
    // Android mobile
    window.mobile.storeAndroidSelectedHospital(JSON.stringify(data));
  }else if (window.MobNative?.storeAndroidSelectedHospital) {
    // Android MobNative
    window.MobNative.storeAndroidSelectedHospital(JSON.stringify(data));
  } else if (USE_LOCAL_STORAGE) {
    localStorage.setItem("storedPreferredHospital", JSON.stringify(data));
  }

  controller.setState({
    selectedHospital: data,
  });
};

export const GetListHospital: Handler = async (controller, params) => {
  console.log(" SelectHospitalInterface GetListHospital ");
  controller.setState({
    loadingHospital: true,
  });
  console.log(" SelectHospitalInterface GetListHospital ");
  const [res, error] = await GetHospital(controller, params);
  console.log(" SelectHospitalInterface GetListHospital ");
  const hospitalList: HospitalType[] = res?.items || [];

  if (params?.sendToNative) {
    //  บันทึก ใส่ native (จาก Pull To Refresh)
    if (globalThis.mobile.saveiOSHospitalList) {
      // iOS
      let hospitalListStr = hospitalList.map((item:HospitalType ) =>  {
        return {...item, patient_id: `${item.patient_id}`}
      })
      globalThis.mobile.saveiOSHospitalList(hospitalListStr);
    } else if (globalThis.mobile.saveHospitalList) {
      // Android
      globalThis.mobile.saveHospitalList(JSON.stringify(hospitalList));
    }
  }
  controller.setState({
    hospitalList: hospitalList,
    loadingHospital: false,
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
export const GetHospital: Handler = async (controller, params) => {
  console.log("SelectHospitalInterface GetHospital params: ", params);
  let lang: string | undefined = window.navigator?.language;

  if (window.mobile?.iOSLang) {
    lang = await window.mobile?.iOSLang;
  }

  const apiToken = Cookies.get("apiToken");

  const api = V3PatientGetHN.get // params.apiv3 ? V3PatientGetHN.get : V3PatientHospital.list

  if (apiToken) {
    let [res, error, network] = await api({
      apiToken: Cookies.get("apiToken"),
      params: {
        search: params.name,
      },
      ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
    });

    console.log("SelectHospitalInterface res: ", res);

    const isThai = lang?.toLowerCase()?.includes("th")

    if (res?.length) {
      res = {
        items: res.map((item: any) => ({
          code: item.organization.code,
          name: isThai ? item.organization.name : item.organization.name_en,
          hn: item.hn,
          patient_id: item.patient_id,
          phone: item.phone,
          email: item.email,
          organization : item.organization
        }))
      }
    }

    return [res, error, network];
  } else {
    return [null, null, null]
  }

};

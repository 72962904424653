import React, { CSSProperties, ReactElement } from "react";
// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import MuiButton from "@mui/material/Button";

// Icon
import CloseIcon from "@mui/icons-material/Close";

import { FormattedMessage } from "react-intl";

// Types
type DialogConfirmProps = {
  open: boolean;
  className?: string;
  title: string | ReactElement;
  description?: string | ReactElement;
  loading?: boolean;
  img?: any;
  approveText?: string | ReactElement;
  denyText?: string | ReactElement;
  imgSize?: string;
  subTitle?: string | ReactElement;
  // styles
  imgStyle?: CSSProperties;
  denyStyle?: CSSProperties;
  approveStyle?: CSSProperties;
  denyBasic?: boolean;
  dialogStyle?: CSSProperties;
  dialogContentStyle?: CSSProperties;
  // config
  hideImage?: boolean;
  hideDeny?: boolean;
  closeIcon?: boolean;
  // callback
  onDeny?: () => any;
  onApprove: () => any;
  // Element
  children?: ReactElement | null;
};

// Images
const IMAGES = {
  medical: "/images/payment/medical.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  grey: "#2c2c2c",
  very_light_blue: "#d6e1f1",
  light_grey: "rgba(121, 120, 120, 1)",
};

const DialogConfirm = (props: DialogConfirmProps) => {
  return (
    <div>
      <Dialog
        open={props.open}
        className={`smart-dialog-container ${props.className}`}
      >
        {props.closeIcon && (
          <div className="close-icon-right">
            <MuiButton
              color="primary"
              variant="text"
              style={{ position: "absolute", top: "20px", right: "20px", 
                zIndex: 10000 }}
            >
              <CloseIcon onClick={props.onDeny} />
            </MuiButton>
          </div>
        )}
        <DialogContent style = {{...props.dialogStyle}}>
          <div className="content" style = {{...props.dialogContentStyle}}>
            {!props.hideImage ? (
              <img
                src={props.img || IMAGES.medical}
                alt="dismiss"
                style={{
                  width: props.imgSize || "9.625rem",
                  margin: "-1rem 0 0rem",
                  ...(props.imgStyle || {}),
                }}
              />
            ) : null}
            <div
              className="title"
              style={{
                margin: "1.15rem 0.5rem 0.55rem",
                lineHeight: 1.35,
                fontSize: "1.375rem",
              }}
            >
              {typeof props.title === "string"
                ? props.title
                    ?.split("\n")
                    .map((text) => <div key={text}>{text}</div>)
                : props.title}
            </div>

            {props.children ? (
              props.children
            ) : (
              <div style={{ color: COLORS.light_grey }}>
                {props.subTitle && (
                  <div style={{ marginBottom: "0.25rem" }}>
                    {props.subTitle}
                  </div>
                )}

                {typeof props.description === "string"
                  ? props.description
                      ?.split("\n")
                      .map((text) => <div key={text}>{text}</div>)
                  : props.description}
              </div>
            )}
          </div>

          <div className="button-submit-bottom" style={{ marginTop: "2rem" }}>
            <MuiButton
              variant="contained"
              color="primary"
              disabled={props.loading}
              style={{ 
                width: "87.5%",
                ...(props.approveStyle || {}),
              }}
              onClick={props.onApprove}
            >
              {props.approveText || <FormattedMessage id="common.confirm" />}
              {props.loading && (
                <CircularProgress
                  size={20}
                  style={{
                    margin: "0 0 -0.1rem 0.5rem",
                  }}
                />
              )}
            </MuiButton>
          </div>

          {!props.hideDeny && (
            <div
              className="button-submit-bottom outline"
              style={{ marginTop: "-1rem" }}
            >
              <MuiButton
                variant="contained"
                color="primary"
                disabled={props.loading}
                style={{
                  width: "87.5%",
                  ...(props.denyBasic
                    ? {
                        fontWeight: "normal",
                        marginBottom: "-1.5rem",
                        border: "none",
                      }
                    : {}),
                  ...(props.denyStyle || {}),
                }}
                onClick={props.onDeny}
              >
                {props.denyText || <FormattedMessage id="common.cancel" />}
              </MuiButton>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(DialogConfirm);
